export const configs = {
    "testFunc": [],
    "assetList": ["BOND", "MLD", "UNLISTED_COMPANY", "GOVERNMENT_SECURITIES", "STARTUP", "PRE_IPO_BUCKET", "MIX_BUCKET"],
    "accountType": ["NORMAL", "PRO", "PRO_TAGGED"],
    "apiBaseUrl": "https://dev.altiusinvestech.com",
    "phoneRegex": /^[6-9]\d{9}$/,
    "emailRegex": /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    "passRegex": /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    "passValidationMessage": "Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.",
    "remarkCategories": {
        "kyc:kyc_remark": "kyc:kyc_remark",
        "buy_module:payment_pending": "buy_module:payment_pending",
        "buy_module:share_delivered": "buy_module:share_delivered",
        "buy_module:intervention_needed": "buy_module:intervention_needed",
        "sell_module:approve_or_reject_generated_order": "sell_module:approve_or_reject_generated_order",
        "sell_module:share_transfer_pending": "sell_module:share_transfer_pending",
        "sell_module:payment_completed": "sell_module:payment_completed"
    },
    "shareStatusTypes":
    {
        "All Orders": { name: "All Orders", api: "", functionName: "buy_module:load_all_buy_orders" },
        "Completed Orders": { name: "Completed Orders", api: "completed", functionName: "buy_module:load_completed_orders" },
        "Rejected Orders": { name: "Rejected Orders", api: "rejected", functionName: "buy_module:load_rejected_orders" },
        "Cancelled Orders": { name: "Cancelled Orders", api: "cancelled", functionName: "buy_module:load_cancelled_orders" },
        "Deleted Orders": { name: "Deleted Orders", api: "deleted", functionName: "buy_module:load_deleted_orders" },
        "Payment Pending": { name: "Payment Pending", api: "generated", functionName: "buy_module:load_generated_orders" },
        "Acknowledgement Pending": { name: "Acknowledgement Pending", api: "initiated", functionName: "buy_module:load_manual_orders_without_confirmation" },
        "Share Transfer Pending": { name: "Share Transfer Pending", api: "acknowledged", functionName: "buy_module:load_acknowledged_orders" },
        "Intervention Needed": { name: "Intervention Needed", api: "intervention_needed", functionName: "buy_module:load_intervention_needed_orders" }
    },
    "sellOrderStatusTypes":
    {
        "All Orders": { name: "All Orders", api: "", functionName: "sell_module:load_all_sell_orders" },
        "Completed Orders": { name: "Completed Orders", api: "completed", functionName: "sell_module:load_completed_orders" },
        "Rejected Orders": { name: "Rejected Orders", api: "rejected", functionName: "sell_module:load_rejected_orders" },
        "Cancelled Orders": { name: "Cancelled Orders", api: "cancelled", functionName: "sell_module:load_cancelled_orders" },
        "Deleted Orders": { name: "Deleted Orders", api: "deleted", functionName: "sell_module:load_deleted_orders" },
        "Customer Generated": { name: "Customer Generated", api: "generated", functionName: "sell_module:load_generated_orders" },
        "Share Transfer Pending": { name: "Share Transfer Pending", api: "shareTransferPending", functionName: "sell_module:load_share_transfer_pending_orders" },
        "Share Transfer Acknowledgement Pending": { name: "Share Transfer Acknowledgement Pending", api: "shareTransfered", functionName: "sell_module:load_share_transfered_orders" },
        "Payment Pending": { name: "Payment Pending", api: "shareTransfereAcknowledged", functionName: "sell_module:load_share_transfer_acknowledged_orders" }
    },
    "customerStatistics": {
        "Without Account": { name: "Without Account", api: "withoutAccount" },
        "No Order Generated": { name: "No Order Generated", api: "noOrderGenerated" },
        "Atleast One Order Generated": { name: "Atleast One Order Generated", api: "atLeastOneOrderGenerated" },
        "KYC Not Initiated": { name: "KYC Not Initiated", api: "kycNotInitiated" },
        "KYC Pending": { name: "KYC Pending", api: "kycPending" },
        "KYC Rejected": { name: "KYC Rejected", api: "kycRejected" },
        "KYC Approved": { name: "KYC Approved", api: "kycApprovedButNoOrderGenerated" },
        "Atleast One Invoice Generated": { name: "Atleast One Invoice Generated", api: "atLeastOneOrderCompleted" }
    },
    "functionList": {
        "kyc:read_user_kyc_info": "kyc:read_user_kyc_info",
        "kyc:update_user_kyc_status": "kyc:update_user_kyc_status",
        "buy_module:update_inventory": "buy_module:update_inventory",
        "buy_module:export_inventory": "buy_module:export_inventory",
        "notification_management:notification_management": "notification_management:notification_management",
        "buy_module:update_buy_order": "buy_module:update_buy_order",
        "buy_module:reject_buy_order": "buy_module:reject_buy_order",
        "buy_module:approve_intervention_needed_buy_order": "buy_module:approve_intervention_needed_buy_order",
        "buy_module:acknowledge_manual_payment_of_buy_order": "buy_module:acknowledge_manual_payment_of_buy_order",
        "buy_module:transfer_shares_of_buy_order": "buy_module:transfer_shares_of_buy_order",
        "buy_module:buy_order_manual_punching": "buy_module:buy_order_manual_punching",
        "buy_module:manual_payment_processing_of_buy_order": "buy_module:manual_payment_processing_of_buy_order",
        "buy_module:load_generated_orders": "buy_module:load_generated_orders",
        "buy_module:load_intervention_needed_orders": "buy_module:load_intervention_needed_orders",
        "buy_module:load_manual_orders_without_confirmation": "buy_module:load_manual_orders_without_confirmation",
        "buy_module:load_acknowledged_orders": "buy_module:load_acknowledged_orders",
        "buy_module:load_rejected_orders": "buy_module:load_rejected_orders",
        "buy_module:load_completed_orders": "buy_module:load_completed_orders",
        "buy_module:load_cancelled_orders": "buy_module:load_cancelled_orders",
        "buy_module:load_all_buy_orders": "buy_module:load_all_buy_orders",
        "buy_module:load_deleted_orders": "buy_module:load_deleted_orders",
        "buy_module:delete_buy_order": "buy_module:delete_buy_order",
        "buy_module:restore_buy_order": "buy_module:restore_buy_order",
        "sell_module:load_all_sell_orders": "sell_module:load_all_sell_orders",
        "sell_module:update_sell_order": "sell_module:update_sell_order",
        "sell_module:reject_sell_order": "sell_module:reject_sell_order",
        "sell_module:approve_order_and_set_status_to_share_transfer_pending": "sell_module:approve_order_and_set_status_to_share_transfer_pending",
        "sell_module:acknowledge_share_transfer": "sell_module:acknowledge_share_transfer",
        "sell_module:mark_order_as_completed": "sell_module:mark_order_as_completed",
        "sell_module:load_generated_orders": "sell_module:load_generated_orders",
        "sell_module:load_share_transfer_pending_orders": "sell_module:load_share_transfer_pending_orders",
        "sell_module:load_share_transfered_orders": "sell_module:load_share_transfered_orders",
        "sell_module:load_share_transfer_acknowledged_orders": "sell_module:load_share_transfer_acknowledged_orders",
        "sell_module:load_completed_orders": "sell_module:load_completed_orders",
        "sell_module:load_cancelled_orders": "sell_module:load_cancelled_orders",
        "sell_module:load_rejected_orders": "sell_module:load_rejected_orders",
        "sell_module:load_deleted_orders": "sell_module:load_deleted_orders",
        "sell_module:delete_sell_order": "sell_module:delete_sell_order",
        "sell_module:restore_sell_order": "sell_module:restore_sell_order",
        "sell_module:sell_order_manual_punching": "sell_module:sell_order_manual_punching",
        "sell_module:transfer_share_on_users_behalf": "sell_module:transfer_share_on_users_behalf",
        "user_management:load_user_details": "user_management:load_user_details",
        "role_management:role_management": "role_management:role_management",
        "remark_management:remark_management": "remark_management:remark_management",
        "social_media:social_media": "social_media:social_media",
        "referral_management:referral_management": "referral_management:referral_management",
        "company_parser:update_company_data": "company_parser:update_company_data",
        "company_parser:export_company_data": "company_parser:export_company_data",
        "company_management:create_company": "company_management:create_company",
        "company_management:update_company": "company_management:update_company",
        "company_management:delete_or_restore_company": "company_management:delete_or_restore_company",
        "company_management:view_company": "company_management:view_company",
        "company_management:update_stocksummary": "company_management:update_stocksummary",
        "company_management:news_management": "company_management:news_management",
        "dashboard_management:manage_dashboard": "dashboard_management:manage_dashboard",
        "kyc:update_distributor_kyc_status": "kyc:update_distributor_kyc_status",
        "kyc:read_distributor_kyc_info": "kyc:read_distributor_kyc_info",
        "distributor_management:load_distributor_details": "distributor_management:load_distributor_details",
        "distributor_management:update_distributor_type": "distributor_management:update_distributor_type",
        "distributor_management:update_distributor_commission": "distributor_management:update_distributor_commission",
        "distributor_management:upload_payout_data_sheet": "distributor_management:upload_payout_data_sheet",
        "distributor_management:manual_entry_in_commission_ledger": "distributor_management:manual_entry_in_commission_ledger",
        "user_management:update_user_utm": "user_management:update_user_utm",
        "bond_management:view_asset_data": "bond_management:view_asset_data",
        "bond_management:create_asset": "bond_management:create_asset",
        "bond_management:update_asset": "bond_management:update_asset",
        "bond_management:delete_or_restore_company": "bond_management:delete_or_restore_company",
        "mld_management:view_asset_data": "mld_management:view_asset_data",
        "mld_management:create_asset": "mld_management:create_asset",
        "mld_management:update_asset": "mld_management:update_asset",
        "mld_management:delete_or_restore_company": "mld_management:delete_or_restore_company",
        "gsec_management:view_asset_data": "gsec_management:view_asset_data",
        "gsec_management:create_asset": "gsec_management:create_asset",
        "gsec_management:update_asset": "gsec_management:update_asset",
        "gsec_management:delete_or_restore_company": "gsec_management:delete_or_restore_company",
        "startup_management:view_asset_data": "startup_management:view_asset_data",
        "startup_management:create_asset": "startup_management:create_asset",
        "startup_management:update_asset": "startup_management:update_asset",
        "startup_management:delete_or_restore_company": "startup_management:delete_or_restore_company",
        "bucket_management:view_asset_data": "bucket_management:view_asset_data",
        "bucket_management:create_asset": "bucket_management:create_asset",
        "bucket_management:update_asset": "bucket_management:update_asset",
        "bucket_management:delete_or_restore_company": "bucket_management:delete_or_restore_company",
        "broadcast:whatsapp": "broadcast:whatsapp",
        "whatsapp:whatsapp": "whatsapp:whatsapp",
        "coupon_management:coupon_management": "coupon_management:coupon_management",
        "crm:add_insight": "crm:add_insight",
        "crm:get_insights": "crm:get_insights",
        "user_pro:user_pro": "user_pro:user_pro",
        "lendbox_data:lendbox_data": "lendbox_data:lendbox_data",
        "liquiloans_data:liquiloans_data": "liquiloans_data:liquiloans_data",
        "user_management:load_user_actions": "user_management:load_user_actions",
        "career_page_management:career_page_management": "career_page_management:career_page_management",
        "order_mandate:order_mandate": "order_mandate:order_mandate"
    },
    "functionListUrl": {
        "kyc:read_user_kyc_info": "/kyc",
        "buy_module:update_inventory": "/shareManagement/inventory",
        "buy_module:export_inventory": "/shareManagement/inventory",
        "notification_management:notification_management": "/notificationManagement",
        "buy_module:buy_order_manual_punching": "/shareManagement/manualPunch",
        "buy_module:load_all_buy_orders": "/shareManagement/buyOrders",
        "sell_module:load_all_sell_orders": "/shareManagement/sellOrders",
        "sell_module:sell_order_manual_punching": "/shareManagement/manualPunch",
        "user_management:load_user_details": "/customerStatistics",
        "role_management:role_management": "/roleManagement",
        "remark_management:remark_management": "/remarkManagemnt",
        "social_media:social_media": "/generateSocialMediaImage",
        "referral_management:referral_management": "/referralManagemnt",
        "company_management:view_company": "/companyManagement/companyDetail",
        "company_management:news_management": "/companyManagement/newsDetail",
        "dashboard_management:manage_dashboard": "/customerDashboardManagement",
        "kyc:update_distributor_kyc_status": "/distributor",
        "kyc:read_distributor_kyc_info": "/distributor",
        "distributor_management:load_distributor_details": "/distributorProfile",
        "bond_management:view_asset_data": "/assetManagement/bond",
        "mld_management:view_asset_data": "/assetManagement/mld",
        "gsec_management:view_asset_data": "/assetManagement/gsec",
        "startup_management:view_asset_data": "/assetManagement/startup",
        "bucket_management:view_asset_data": "/assetManagement/bucket",
        "broadcast:whatsapp": "/whatsappBroadcast",
        "coupon_management:coupon_management": "/couponManagemnt",
        "crm:add_insight": "/crm",
        "crm:get_insights": "/crm",
        "user_pro:user_pro": "/proLeads",
        "lendbox_data:lendbox_data": "/lendbox/users",
        "liquiloans_data:liquiloans_data": "/liquiloan/users",
        "career_page_management:career_page_management": "/careerManagemnt",
        "order_mandate:order_mandate": "/mandate"
    },
    "modules": {
        "kyc": "kyc",
        "distKYC": "distKYC",
        "distFormLead": "distFormLead",
        "custProLead": "custProLead",
        "custActions": "custActions",
        "custProPayment": "custProPayment",
        "custXclusivePayment": "custXclusivePayment",
        "custXclusiveLead": "custXclusiveLead",
        "custProReport": "custProReport",
        "custProPricing": "custProPricing",
        "CRM": "CRM",
        "NoUser": "NoUser",
        "OrderInsight": "OrderInsight",
        "rm": "rm",
        "cm": "cm",
        "am": "am",
        "mn": "mn",
        "mnt": "mnt",
        "whatsappBroadcast": "whatsappBroadcast",
        "whatsappMessage": "whatsappMessage",
        "newsManage": "newsManage",
        "smInventory": "smInventory",
        "manualInventoryUpdate": "manualInventoryUpdate",
        "smBuyOrder": "smBuyOrder",
        "smSellOrder": "smSellOrder",
        "manualPunch": "manualPunch",
        "mandate": "mandate",
        "custProf": "custProf",
        "custProf": "custProf",
        "distProf": "distProf",
        "custStat": "custStat",
        "distStat": "distStat",
        "distComm": "distComm",
        "distCommLed": "distCommLed",
        "distPayout": "distPayout",
        "socialMediaImage": "socialMediaImage",
        "remarkMaster": "remarkMaster",
        "referralMaster": "referralMaster",
        "distreferralMaster": "distreferralMaster",
        "custDashMng": "custDashMng",
        "custDashHighMng": "custDashHighMng",
        "distDashMng": "distDashMng",
        "promoMgmt": "promoMgmt",
        "mldMgmt": "mldMgmt",
        "gsecMgmt": "gsecMgmt",
        "bondMgmt": "bondMgmt",
        "startupMgmt": "startupMgmt",
        "bucketMgmt": "bucketMgmt",
        "mixBucketMgmt": "mixBucketMgmt",
        "couponMgmt": "couponMgmt",
        "careerMgmt": "careerMgmt",
        "lbkyc": "lbkyc",
        "lbinvest": "lbinvest",
        "lbCase": "lbCase",
        "lbwithdraw": "lbwithdraw",
        "lqkyc": "lqkyc",
        "lqinvest": "lqinvest",
        "lqwithdraw": "lqwithdraw",
        "lqCase": "lqCase",
    },
    "statusValues": {
        "rejected": "rejected",
        "verified": "verified",
        "pending": "pending",
        "initial": "initial"
    },
    "docTypes": {
        "address_proof": "address_proof",
        "bank_proof": "bank_proof",
        "demat_proof": "demat_proof",
        "pan_card": "pan_card",
        "other_document": "other_document"
    },
    "fields": {
        "address": "address",
        "name": "name",
        "pan": "pan"
    },
    "unauthorizedErrorMessage": "Invalid token or unauthorized agent.",
    "createRoleError": {
        "name": "Role Name is required.",
        "desc": "Role Description is required."
    }
}

export const formatCurrency = (amount) => {
    amount = isNaN(amount) ? 0 : amount
    return amount.toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'INR'
    })
}

export const formatNumber = (number) => {
    number = isNaN(number) ? 0 : number
    return parseInt(number).toLocaleString('en-IN')
}

// show pdf in new tab
export function showDocNewTab(title, doc) {
    try {
        var win = window.open("#", "_blank");
        win.document.write('<html><title>' + title + '</title><body">');
        if (doc.includes("application/pdf")) {
            let res = convertToBlob(doc)
            win.document.write(`<iframe id="newFrame" src=${URL.createObjectURL(res)} height="100%" width="100%"></iframe>`)
        } else {
            win.document.write(`<embed className="docs-box" src=${doc} type="application/pdf" width="100%" height="100%" /> `);
        }
        win.document.write('</body></html>');
    } catch (error) {

    }
}

// download documents
export function downloadDoc(name, data) {
    try {
        let a = document.createElement("a"); //Create <a>
        a.href = data; //Image Base64 Goes here
        a.download = data.includes("application/pdf") ? `${name}.pdf`
            : data.includes("image/png") ? `${name}.png`
                : `${name}.jpg`; //File name Here
        a.click();

    } catch (error) {
        console.error(error)
    }

}

// download image
export async function downloadImage(imageSrc, name) {
    try {
        const image = await fetch(imageSrc)
        const imageBlog = await image.blob()
        const imageURL = URL.createObjectURL(imageBlog)

        const link = document.createElement('a')
        link.href = imageURL
        link.download = name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    } catch (error) {
        console.error(error)
    }
}

// render large document by converting to blob 
export function convertToBlob(data, frameId, sliceSize = 512, contentType = "application/pdf") {
    try {
        let temp = data.split("base64,")
        const byteCharacters = window.atob(temp[1])
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        if (frameId) {
            let ele = document.getElementById(frameId)
            ele.src = URL.createObjectURL(blob)
        } else {
            return blob
        }
    } catch (error) {
        console.error(error)
    }
}

// hide and show left bar menu
export function showLeftBar(show) {
    try {
        if (show) {
            document.getElementById("leftBar").classList.toggle("d-none")
        } else {
            if (!document.getElementById("leftBar").classList.value.includes("d-none")) {
                document.getElementById("leftBar").classList.toggle("d-none");
            }
        }
    } catch (error) {

    }
}

// const download csv file
export function downloadCSVData(datam, name) {
    try {
        let data = datam.map((datao, index) => flattenObject(datao));
        var keys = Object.keys(data[0])
        const commaSeparatedString = [keys.join(","),
        ...data.map(obj =>
            keys.reduce(
                (acc, key) =>
                    `${acc}${!acc.length ? '' : ","}"${!obj[key] ? '' : obj[key]}"`,
                '')
        )].join('\n');

        let csvContent = "data:text/csv;charset=utf-8,"
            + commaSeparatedString;
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `${name}.csv`);
        document.body.appendChild(link); // Required for FF

        link.click();

    } catch (error) {
        console.error(error)
    }
}

export function flattenObject(obj) {
    try {
        let flattenKeys = {};
        for (let i in obj) {
            if (!obj.hasOwnProperty(i)) continue;
            if (typeof obj[i] == 'object' && obj[i] !== null) {
                // flattenKeys[i] = obj[i];
                let flatObject = flattenObject(obj[i]);
                for (let j in flatObject) {
                    if (!flatObject.hasOwnProperty(j)) continue;
                    flattenKeys[i + '.' + j] = flatObject[j] ? flatObject[j] : "";
                }
            } else {
                flattenKeys[i] = obj[i];
            }
        }
        return flattenKeys;
    } catch (error) {
        console.error(error);
    }
}

export function generateBase64(file) {
    return new Promise((resolve, reject) => {

        const fileReader = new FileReader();
        fileReader.readAsDataURL(file);

        fileReader.onload = () => {
            resolve(fileReader.result);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };

    });
}